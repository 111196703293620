import React from 'react';
import Feature from '../../components/feature-two/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Step 1.',
    text: 'Firstly, download our app. You can easily do that by finding our app called FoodEx - QR code menu, now available on Google Play or App Store ',
  },
  {
    title: 'Step 2.',
    text: 'Now you can create your profile by following the steps listed inside the app. The app requires restaurant name, location and image.',
  },
  {
    title: 'Step 3.',
    text: 'After finishing your restaurant registration, you will upload your menu by following simple steps. The working principal of the app is based on categories and items that you will add.',
  },
  {
    title: 'Step 4.',
    text: 'Now you can save your QR code to your gallery (explained in the app) and print it as a sticker (or in any other kind of way that you prefere).',
  },
  {
    title: 'Finishing up.',
    text: 'And that is it. You are all ready to go. Users can approach your menu by scanning the QR code through our FoodEx app or through any QR code scanning software. Enjoy :)',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">How to register my restaurant/cafe bar?</h1>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
