import React from 'react';
import possibilityImage from '../../assets/possibility.png';
import './possibility.css';
import fork from '../../fork-anim.json';
import Lottie from 'lottie-react';
import Feature from '../../components/feature-two/Feature';
import { Link } from 'react-router-dom';

const featuresData = [
  {
    title: '1.',
    text: 'FoodEx app is completely free to use. We do not charge customers for creating digital menu with no limits at all.',
  },
  {
    title: '2.',
    text: 'App has a Privacy Polici and Terms & Conditions which you can also approach by clicking on the buttons above.',
  },
  {
    title: '3.',
    text: 'If you are restaurant or a cafe bar owner and you wont use our platform to create a digital menu, we expect professionalism and responsibility for the content you post.',
  },
];

const Possibility = () => (
  <div className="gpt3__possibility section__padding" id="possibility">
    <div className="gpt3__possibility-image">
      <Lottie className='fork' animationData={fork}/>
    </div>
    <div className="gpt3__possibility-content">
      <h1 className="gradient__text">Enable users to easily approach your menu through their phones</h1>
      <p>Digitalization is very common these days so do not miss a chance to register and create your digital menu for free and with no limits. Through our app you can create categories and place items inside. Every category can contain a name (it can be writen in 4 languages) and an image. Items inside can contain a name, description, price and an image.</p>
      
      <div className="gpt3__cta-btn-1">
        <Link to='/privacypolicy'><button type="button">Privacy Policy</button></Link>
        <Link to='/terms&conditions'><button type="button">Terms & Conditions</button></Link>
      </div>

      <div className="gpt3__features section__padding" id="features">
        <div className="gpt3__features-container">
          {featuresData.map((item, index) => (
            <Feature title={item.title} text={item.text} key={item.title + index} />
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default Possibility;
