import React from 'react';
import gpt3Logo from '../../logo.svg';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">Don't miss a chance and download FoodEx app now</h1>
    </div>

    <div className=" d-flex align-items-center justify-content-center">
                <div className="icons__container">
                <span className="facebook__icon">
                    <i class="ri-facebook-circle-line"></i>
                </span>

                <a href="https://instagram.com/foodex_app?igshid=ZDdkNTZiNTM="
                  target="_blank"
                  rel="noreferrer">
                  <span className="instagram__icon">
                    <i class="ri-instagram-line"></i>
                </span>
                </a>


                <span className="youtube__icon">
                    <i class="ri-youtube-line"></i>
                </span>
                </div>
            </div>

    <div className="gpt3__footer-copyright">
      <p>@2023 foodexcroatia. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
