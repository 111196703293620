import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="Who are we" text="We are completely free platfom for digital menus. Whether you own a restaurant or cafe bar, digital menu is great option to improve your service. We know we are new on the market, but focus on customer satisfaction and trust is something we really think we have." />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">We also plan on improving FoodEx, to offer even more possibilities..</h1>
      <h3 className="gradient__text">Our plans:</h3>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Introducing Rewievs" text="We plan on introducing a posibility reviewing your food in our FoodEx app. This is something we are working on so stay tuned." />
      <Feature title="Introducing Blog" text="We also plan on enabling users to post images in our app which will help very much in making marketing easier and more effective." />
      <Feature title="Introducing Navigation" text="One of our plans is definitely adding a possibility of in app navigation." />
    </div>
  </div>
);

export default WhatGPT3;
