import React from 'react';
import './cta.css';
import logo from '../../foodex_logo.png';

const CTA = () => (
  <div className="gpt3__cta">
    <div className="gpt3__cta-content">
      <p>Click on the buttons to download the app.</p>
      <h3>Don't miss a chance to download our app</h3>
    </div>
    <div className='logo__image'>
      <img src={logo}></img>
    </div>
    <div className="gpt3__cta-btn">
      <button type="button"> 
        <a href="https://play.google.com/store/apps/details?id=com.foodexappcroatia"
            target="_blank"
            rel="noreferrer">
           Google Play
        </a></button>
      <button type="button">
      <a href="https://apps.apple.com/hr/app/foodex-qr-code-menu/id1662943436"
            target="_blank"
            rel="noreferrer">
           Apple Store
        </a></button>
    </div>
  </div>
);

export default CTA;
