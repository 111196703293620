import logo from './logo.svg';
import './App.css';
import { Footer, Blog, Possibility, Features, WhatGPT3, Header } from './containers';
import { CTA, Brand, Navbar } from './components';

const Main = () => {
    return (
    <div className='App' >
      <div className="gradient__bg">
        <Navbar />
        <Header />
      </div>
      
      <WhatGPT3 />
      <Features />
      <Possibility />
      <CTA />
      <Footer />
    </div>  
    )
}

export default Main