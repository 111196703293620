import React from 'react';
import people from '../../assets/people.png';
import ai from '../../assets/ai.png';
import qrCode from '../../qr-code-menu-animation.json';
import './header.css';
import Lottie from 'lottie-react';

const Header = () => (
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">Create QR Code Menu For Your Restaurant/Cafe bar...</h1>
      <p>We are sure you won't be sorry if you choose us for your QR code menu backend platform. It is very important that your digital menu looks fashionable and beautiful, so that is exactly what we offer. Our focus isn't only on speed and reliability, but we also want your restaurant/cafe bar menu to give relaxing and enjoyable feeling. For furthure question you can contact us at: </p>
      
      <h2 className="gradient__text">foodex.app.info@gmail.com</h2>

    </div>

    <div>
      <Lottie className='qr__code' animationData={qrCode} />
    </div>
  </div>
);

export default Header;
